import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import Header2 from "../../../components/Header2/header2"
import Footer from "../../../components/property-footer"
import GetAreGuide from "../../../components/get-area-guide"
import SoldLetFilter from "../../../components/soldletfilter"
import { Helmet } from "react-helmet"
import _ from "lodash"
//
import $ from "jquery"
import qs from "qs"
import {
  parseSearchUrl,
  propertyH1,
  ACTION_TYPES,
} from "../../../components/property-search/utils"
import algoliasearch from "algoliasearch/lite"
import {
  connectStats,
  SortBy,
  connectInfiniteHits,
  InstantSearch,
  RefinementList,
  connectSearchBox,
  connectMenu,
  connectRange,
  Configure,
  connectSortBy,
  ToggleRefinement,
  connectToggleRefinement

} from "react-instantsearch-dom"
import Cookies from "universal-cookie"
import { navigate } from "@reach/router"
import SeoResults from "../../../components/property-search/seoResults"
import { ShowProcessedImage } from '../../../components/common/ggfx-client/module/components/show-image';
import "../assets/styles/_index.scss"
//

import { Exclamation, Search, Equalto, Notification, Filter, MapPointer, Bedroom, Bathroom, Reception, Kitchen } from '../../../components/icon';
import imageConfig from "../../../../static/images/config.json"
import SeoStructuredData from "../../../components/Seo/StructuredData"

const mycookies = new Cookies()
const updateAfter = 500

var includeSold = false;

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""

const searchClient = algoliasearch(
  `${process.env.GATSBY_ALGOLIA_APP_ID}`,
  `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
)

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`

// Property resutls loop
const InfiniteHits = ({
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
  location,
}) => (
  <>
    <Row>
      <Col lg={12} className="text-center">
        {hits.length > 0 && hasPrevious && (
          <button
            id="myprevbut"
            onClick={refinePrevious}
            disabled={!hasPrevious}
            className="text-uppercase d-none property_show_more_btn"
          >
            Show prev
          </button>
        )}
      </Col>
    </Row>

    {hits.map(hit => {
      // property details url structure
      let uriStr = ""

      if (hit.searchType === "sales") {
        uriStr = `property-for-sale/`
      }
      // property details url structure

      //property description
      let text_truncate = function (str, length, ending) {
        if (length == null) {
          length = 150
        }
        if (ending == null) {
          ending = "..."
        }
        if (str.length > length) {
          return str.substring(0, length - ending.length) + ending
        } else {
          return str
        }
      }
      if(hit.status == 'Sold' || hit.status =='Sold STC') {
        var prostatus = hit.status
      } else {
        var prostatus = ''
      }
      // property description
      let ssimage = imageConfig.property.searchResults.sizes;

      const cloud_url = process.env.GATSBY_SITE_URL;
      return (
        <>
          <SeoStructuredData
            type = "SearchResultsPage"
            entity={
              {
                list: _.map(hits, o => _.extend({
                  name: `${o.bedroom} bed ${o.building ? o.building+' ' : ''}${o.status} in ${o.address}`,
                  url: process.env.GATSBY_SITE_URL + '/property-for-sale/' + o.slug + '-' + o.objectID
                }, o))
              }
            }
          />
          <div
            id={`mySaleProp${hit.objectID}`}
            className="result-list d-xl-flex"
          >
            <figure className="result-img">
              <Link
                onClick={() =>
                  mycookies.set("mySaleProp", hit.objectID, {
                    path: "/",
                    maxAge: 300,
                  })
                }
                to={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                state={{ referrer: location.pathname }}
              >
                {hit.images &&
                <ShowProcessedImage images={hit.images[0]} attr={{ className: 'mb-0 img-fluid', alt: ''}} transforms={imageConfig.property.searchResults.sizes} />
    }
              </Link>
              {prostatus &&
              <div className='propertyStatus'>{hit.status}</div>
              }
            </figure>
            <div className="result-desc">
              <h2>{hit.display_address}</h2>
              <span className="price-info">
                £{hit.price.toLocaleString()}
              </span>
              <span className="room-info">{hit.title}</span>

                <div className='room-list'>
                  {hit.bedroom !=null && hit.bedroom > 0 &&
                  <div className="room-icons">
                    <Bedroom/>
                    <span>{hit.bedroom} {hit.bedroom > 1 ? 'Bedrooms' : 'Bedroom'}</span>
                  </div>
                    }
                    {hit.bathroom !=null && hit.bathroom > 0 &&
                  <div className="room-icons">
                    <Bathroom/>
                    <span>{hit.bathroom} {hit.bathroom > 1 ? 'Bathrooms' : 'Bathroom'}</span>
                  </div>
                  }
                  {hit.reception !=null && hit.reception > 0 &&
                  <div className="room-icons">
                    <Reception/>
                    <span>{hit.reception} {hit.reception > 1 ? 'Receptions' : 'Reception'}</span>
                  </div>
                  }
                  {hit.kitchen !=null && hit.kitchen > 0 &&
                  <div className="room-icons">
                    <Kitchen/>
                    <span>{hit.kitchen} {hit.kitchen > 1 ? 'Kitchens' : 'Kitchen'}</span>
                  </div>
                  }
</div>

              <p className="d-none d-md-block">
                {text_truncate(hit.description).replace(/<br\s*\/?>/g, ' ')}
              </p>
              <div className="btn-wrap d-flex align-items-center justify-content-between">
                <Link
                  onClick={() =>
                    mycookies.set("mySaleProp", hit.objectID, {
                      path: "/",
                      maxAge: 300,
                    })
                  }
                  to={`/${uriStr}${hit.slug}-${hit.objectID}/`}
                  state={{ referrer: location.pathname }}
                  className="btn-view"
                >
                  View Details
                </Link>
                <GetAreGuide postcode={hit?.postcode?.split(" ")[0]} />
              </div>
            </div>
          </div>
        </>
      )
    })}

    <Row>
      <Col lg={12} className="text-center">
        {hits.length > 0 && hasMore && (
          <button
            id="mynextbut"
            onClick={refineNext}
            disabled={!hasMore}
            className="btn btn-default"
          >
            Show more
          </button>
        )}
      </Col>
    </Row>
  </>
)

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)
// Property resutls loop

// Property Search
const PropertySearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  const handleSearchQuery = (e) =>  {
    let str = e.target.value.trim();
    let fixedStr = str?.replace(/\s+/g, ' ');
    refine(fixedStr);
  }
  return (
    <Form className="filter-form" action="javascript:void(0)">
      <Form.Group controlId="formBasicEmail">
        <Form.Control
          type="text"
          name="search"
          onChange={handleSearchQuery}
          placeholder="Street, area or postcode"
        />
      </Form.Group>
      <button className="btn-search" type="submit">
        <Search />
      </button>
    </Form>
  )
})
// Property Search

// Property Min price
const priceminRange = [
  {
    value: "50000",
    label: "£50,000",
  },
  {
    value: "60000",
    label: "£60,000",
  },
  {
    value: "70000",
    label: "£70,000",
  },
  {
    value: "80000",
    label: "£80,000",
  },
  {
    value: "90000",
    label: "£90,000",
  },
  {
    value: "100000",
    label: "£100,000",
  },
  {
    value: "120000",
    label: "£120,000",
  },
  {
    value: "140000",
    label: "£140,000",
  },
  {
    value: "160000",
    label: "£160,000",
  },
  {
    value: "180000",
    label: "£180,000",
  },
  {
    value: "200000",
    label: "£200,000",
  },
  {
    value: "250000",
    label: "£250,000",
  },
  {
    value: "300000",
    label: "£300,000",
  },
  {
    value: "350000",
    label: "£350,000",
  },
  {
    value: "400000",
    label: "£400,000",
  },
  {
    value: "500000",
    label: "£500,000",
  },
  {
    value: "600000",
    label: "£600,000",
  },
  {
    value: "700000",
    label: "£700,000",
  },
  {
    value: "800000",
    label: "£800,000",
  },
  {
    value: "900000",
    label: "£900,000",
  },
  {
    value: "1000000",
    label: "£1,000,000",
  },
  {
    value: "1250000",
    label: "£1,250,000",
  },
  {
    value: "1500000",
    label: "£1,500,000",
  },
  {
    value: "1750000",
    label: "£1,750,000",
  },
  {
    value: "2000000",
    label: "£2,000,000",
  },
  {
    value: "2500000",
    label: "£2,500,000",
  },
  {
    value: "3000000",
    label: "£3,000,000",
  },
  {
    value: "4000000",
    label: "£4,000,000",
  },
  {
    value: "4500000",
    label: "£4,500,000",
  },
  {
    value: "5000000",
    label: "£5,000,000",
  },
  {
    value: "6000000",
    label: "£6,000,000",
  },
  {
    value: "7000000",
    label: "£7,000,000",
  },
  {
    value: "8000000",
    label: "£8,000,000",
  },
  {
    value: "9000000",
    label: "£9,000,000",
  },
  {
    value: "10000000",
    label: "£10,000,000",
  },
]

const minpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let minPriceRange = priceminRange
  if (currentRefinement.max !== undefined) {
    minPriceRange = priceminRange.filter(
      x => parseInt(x.value) < parseInt(currentRefinement.max)
    )
  }
  return (
    <select
      name="minprice"
      value={currentRefinement.min || ""}
      onChange={event => {
        minpriceval = event.currentTarget.value
        if (event.currentTarget.value === "") {
          if (maxpriceval) {
            refine({
              max: maxpriceval,
            })
          } else refine(event.currentTarget.value)
        } else {
          refine({
            min: event.currentTarget.value,
            max: maxpriceval,
          })
        }
        $('html, body').animate({
          scrollTop: $(".result-head").offset().top - 250
        }, 1000);
      }}
    >
      <option value="">Min Price</option>
      {minPriceRange.map((option, key) => {
        return (
          <option value={option.value} key={key}>
            {option.label}
          </option>
        )
      })}
    </select>
  )
}

const CustomminpriceSelectbox = connectRange(minpriceSelectbox)
// Property Min price

// Include sold and let
const ToggletttRefinement = ({
    currentRefinement,
    label,
    count,
    refine,
    createURL,
  }) => {
    return (
      <a
        href={'javascripti:;'}
        onClick={event => {
          event.preventDefault();
          includeSold = !includeSold;
          refine(!currentRefinement);
        }}
      >
        <div class="ais-ToggleRefinement">
            <label class="ais-ToggleRefinement-label">
                <input type="checkbox" class="ais-ToggleRefinement-checkbox" value="" id="flexCheckDefault" checked={includeSold ? "checked" : ""} />
                <span class="ais-ToggleRefinement-labelText">{label}</span>
            </label>
        </div>
      </a>
    )
}

const CustomToggleRefinement = connectToggleRefinement(ToggletttRefinement);
// Include sold and let

// Property Max price
const pricemaxRange = [
  {
    value: "50000",
    label: "£50,000",
  },
  {
    value: "60000",
    label: "£60,000",
  },
  {
    value: "70000",
    label: "£70,000",
  },
  {
    value: "80000",
    label: "£80,000",
  },
  {
    value: "90000",
    label: "£90,000",
  },
  {
    value: "100000",
    label: "£100,000",
  },
  {
    value: "120000",
    label: "£120,000",
  },
  {
    value: "140000",
    label: "£140,000",
  },
  {
    value: "160000",
    label: "£160,000",
  },
  {
    value: "180000",
    label: "£180,000",
  },
  {
    value: "200000",
    label: "£200,000",
  },
  {
    value: "250000",
    label: "£250,000",
  },
  {
    value: "300000",
    label: "£300,000",
  },
  {
    value: "350000",
    label: "£350,000",
  },
  {
    value: "400000",
    label: "£400,000",
  },
  {
    value: "500000",
    label: "£500,000",
  },
  {
    value: "600000",
    label: "£600,000",
  },
  {
    value: "700000",
    label: "£700,000",
  },
  {
    value: "800000",
    label: "£800,000",
  },
  {
    value: "900000",
    label: "£900,000",
  },
  {
    value: "1000000",
    label: "£1,000,000",
  },
  {
    value: "1250000",
    label: "£1,250,000",
  },
  {
    value: "1500000",
    label: "£1,500,000",
  },
  {
    value: "1750000",
    label: "£1,750,000",
  },
  {
    value: "2000000",
    label: "£2,000,000",
  },
  {
    value: "2500000",
    label: "£2,500,000",
  },
  {
    value: "3000000",
    label: "£3,000,000",
  },
  {
    value: "4000000",
    label: "£4,000,000",
  },
  {
    value: "4500000",
    label: "£4,500,000",
  },
  {
    value: "5000000",
    label: "£5,000,000",
  },
  {
    value: "6000000",
    label: "£6,000,000",
  },
  {
    value: "7000000",
    label: "£7,000,000",
  },
  {
    value: "8000000",
    label: "£8,000,000",
  },
  {
    value: "9000000",
    label: "£9,000,000",
  },
  {
    value: "10000000",
    label: "£10,000,000",
  },
]

const maxpriceSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let maxPriceRange = pricemaxRange
  let maxpricedisable = ""
  if (currentRefinement.min !== undefined) {
    maxPriceRange = pricemaxRange.filter(
      x => parseInt(x.value) > parseInt(currentRefinement.min)
    )
  }
  if (currentRefinement.min == "10000000") {
    maxpricedisable = "disabled"
  }
  return (
    <select
      name="maxprice"
      disabled={maxpricedisable}
      value={currentRefinement.max || ""}
      onChange={event => {
        maxpriceval = event.currentTarget.value
        if (event.currentTarget.value === "") {
          if (minpriceval) {
            refine({
              min: minpriceval,
            })
          } else refine(event.currentTarget.value)
        } else {
          refine({
            min: minpriceval,
            max: event.currentTarget.value,
          })
        }
        $('html, body').animate({
          scrollTop: $(".result-head").offset().top - 250
        }, 1000);
      }}
    >
      <option value="">Max Price</option>
      {maxPriceRange.map((option, key) => {
        return (
          <option value={option.value} key={key}>
            {option.label}
          </option>
        )
      })}
    </select>
  )
}

const CustommaxpriceSelectbox = connectRange(maxpriceSelectbox)
// Property Max price

// Property Bedrooms
const bedroomSelectbox = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let bedroomvalselect = ""
  if (currentRefinement.max !== undefined) {
    bedroomvalselect = 0
  } else if (currentRefinement.min !== undefined) {
    bedroomvalselect = currentRefinement.min
  }
  return (
    <>
      <select
        name="bedroom"
        value={bedroomvalselect}
        onChange={event => {
          if (event.currentTarget.value === "") {
            refine(event.currentTarget.value)
          } else if (event.currentTarget.value === "0") {
            refine({
              max: event.currentTarget.value,
            })
          } else {
            refine({
              min: event.currentTarget.value,
            })
          }
          $('html, body').animate({
            scrollTop: $(".result-head").offset().top - 250
          }, 1000);
        }}
      >
        <option value="">Bedrooms</option>
        <option value="1" key="1">1 +</option>
        <option value="2" key="2">2 +</option>
        <option value="3" key="3">3 +</option>
        <option value="4" key="4">4 +</option>
        <option value="5" key="5">5 +</option>
      </select>
    </>
  )
}

const CustombedroomSelectbox = connectRange(bedroomSelectbox)
// Property Bedrooms

// Property type
const buildingSelectbox = ({ items, currentRefinement, refine }) => (
  <select
    name="building"
    value={currentRefinement || ""}
    onChange={event => refine(event.currentTarget.value)}
  >
    <option value="">Property Type</option>
    <option value="apartments" key="apartments">
      Apartments
    </option>
    <option value="houses" key="houses">
      Houses
    </option>
  </select>
)

const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type

// Property Sortby
const SortByPrice = ({ items, currentRefinement, refine }) => (
  <>
    {items.map((item, key) => {
      return (
        <button
          key={key}
          className={item.class + " btn-price"}
          onClick={event => {
            event.preventDefault()
            refine(item.value)
          }}
        >
          <Filter />
          {item.label} <span className="d-none d-md-inline">First</span>
        </button>
      )
    })}
  </>
)

const CustomSortBy = connectSortBy(SortByPrice)
// Property Sortby

// No Results
const NoStats = ({ processingTimeMS, nbHits }) => {
  return (
    <>
      {nbHits === 0 && (
        <Row>
          <Col lg={12} className="text-center mb-5">
            <div>Unfortunately, we do not currently have any properties that match your search criteria.</div>
          </Col>
        </Row>
      )}
    </>
  )
}

const CustomNoStats = connectStats(NoStats)
// No Results

// Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Results count

// Create URL
export const createURL = (props, state) => {

  let pathName = props.location.pathname;
  let storageArea = "Manchester";
  if(typeof window !== "undefined"){
    storageArea = localStorage.getItem("area");
    if(pathName.includes("london")) {
      storageArea = 'London';
    }
    if(pathName.includes("manchester")) {
      storageArea = 'Manchester';
    }
  }
    
  // if (props.location.pathname == "/property/for-sale/in-london") {
  //   localStorage.setItem("area", "London");
  // }
  // if (props.location.pathname == "/property/for-sale/in-manchester") {
  //   localStorage.setItem("area", "Manchester");
  // }
  // let storageArea = typeof window !== "undefined" ? localStorage.getItem("area") : null

  let myarea = state.menu

  const isDefaultRoute =
    !state.query &&
    !state.connectSortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.bedroom

  if (isDefaultRoute) {
    return ""
  }

  var areaPath = storageArea === "London" ? "in-london/" : storageArea === "Manchester" ? "in-manchester/" : "in-london-and-manchester/"
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var propertypath = ""
  var sortPath = ""

  if (state.query) {
    if (state.query) {
      state.query = state.query.split(" ").join("-").toLowerCase();
      areaPath =
        "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    }
    areaPath = areaPath.toLowerCase()
  }

  const queryParameters = {}

  // Property path
  if (state.menu) {
    if (myarea["building"]) {
      if(storageArea == "Manchester" && myarea["building"] == "apartments"){  // For Manchester area properties algolia building fileld named "apartment" instead of "apartments"
        myarea["building"] = "apartment"   // So I have changed the build field value apartment for only Manchester properties
      }
      propertypath = "type-" + myarea["building"] + "/"
    }
  }
  // Property path

  // Bedrooms & Price range path
  if (state.range) {
    // Bedrooms
    if (
      state.range.hasOwnProperty("bedroom") &&
      state.range.bedroom.max === 0
    ) {
      bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
    }
    if (state.range.bedroom) {
      if (state.range.bedroom.min) {
        bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
      }
    }

    // Price
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath =
          "between-" +
          state.range.price.min +
          "-and-" +
          state.range.price.max +
          "/" //queryParameters.price_min = state.range.price.min;
      } else if (state.range.price.max) {
        pricePath = "under-" + state.range.price.max + "/" //queryParameters.price_max = state.range.price.max;
      } else if (state.range.price.min) {
        pricePath = "over-" + state.range.price.min + "/" //queryParameters.price_max = state.range.price.max;
      }
    }
  }
  // Bedrooms & Price range path

  // Sort path
  if (state.sortBy) {
    if (state.sortBy === index_name + "_price_asc") {
      sortPath = "sortby-price-asc/"
    }
    if (state.sortBy === index_name) {
      sortPath = ""
    }
  }
  // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })

  let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${queryString}${pagePath}${sortPath}`
  if ("/" === myUrl.substr(myUrl.length - 1))
    myUrl = myUrl.substr(0, myUrl.length - 1)

  return `/property/for-sale/${myUrl}/`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState) =>
  searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
  if (location) {
    var query = ""
    let pathUri_main = location.pathname.split("/property/for-sale")

    var areaVal = ""
    var bedVal = 11
    var minpriceVal = ""
    var maxpriceVal = ""
    var pageVal = ""
    var propertyval = ""
    var sortVal = ""
    var sortVal_filt = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          propertyval = pathUri[vi].replace("type-", "")
        }

        // Area
        if (pathUri[vi].indexOf("in-") >= 0) {
          if(pathUri[vi].indexOf("in-london") >= 0 || pathUri[vi].indexOf("in-manchester") >= 0) {
            areaVal = "";
          }  else {
            areaVal = pathUri[vi].replace("in-", "")
          }
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("over-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }

        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          sortVal_filt = pathUri[vi].replace("sortby-", "")

          if (sortVal_filt === "price-asc") {
            sortVal = index_name + "_price_asc"
          }

          if (sortVal_filt === "price-desc") {
            sortVal = index_name + "_price_desc"
          }
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  // Property type
  if (propertyval) {
    query += `&menu[building]=` + propertyval
  }

  // Area
  if (areaVal) {
    areaVal = areaVal.toLowerCase()
    if (areaVal == "london-and-manchester") query += `&query=`
    else query += `&query=` + areaVal
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }

  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }

  // Bedrooms
  if (bedVal == 0) {
    query += `&range[bedroom][min]=` + 0
  } else if (bedVal < 11) {
    query += `&range[bedroom][min]=` + bedVal
  }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  return qs.parse(query)
}
// URL to searchstate

class PropertySearchSales extends React.Component {
  //
  state = {
    searchState: urlToSearchState(this.props.location),
    includeSoldinside: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) })
    }
  }

  onSearchStateChange = (searchState) => {
    clearTimeout(this.debouncedSetState)
    this.debouncedSetState = setTimeout((e) => {
      // console.log('aaaaaaaa',e)
    navigate(searchStateToUrl(this.props, searchState), searchState)
    }, updateAfter)
    this.setState({ searchState })
  }

  /*onSearchStateChange2 = (searchState) => {
      this.setState({ searchState })
  }*/
  //

  componentDidMount() {
    if (window.location.href.indexOf("property/for-sale")) {
      // Infinite properties scroll
      $(window).scroll(function () {
        // if ($("#mynextbut").length) {
        //   var top_of_element = $("#mynextbut").offset().top
        //   var bottom_of_element =
        //     $("#mynextbut").offset().top + $("#mynextbut").outerHeight()
        //   var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight()
        //   var top_of_screen = $(window).scrollTop()

        //   if (
        //     bottom_of_screen > top_of_element &&
        //     top_of_screen < bottom_of_element
        //   ) {
        //     $("#mynextbut").trigger("click")
        //     //window.scrollTo({ top: top_of_element-100, behavior: 'smooth' });
        //     // the element is visible, do something
        //   }
        // }

        if ($("#myprevbut").length) {
          var top_of_element = $("#myprevbut").offset().top
          var bottom_of_element =
            $("#myprevbut").offset().top + $("#myprevbut").outerHeight()
          var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight()
          var top_of_screen = $(window).scrollTop()

          if (
            bottom_of_screen > top_of_element &&
            top_of_screen < bottom_of_element
          ) {
            $("#myprevbut").trigger("click")
          }
        }
      })
      // Infinite properties scroll
    }

    // Sortby option price
    $(".property_sortby_asc").click(function () {
      $(".property_sortby_asc").addClass("active")
      $(".property_sortby_desc").removeClass("active")
    })

    $(".property_sortby_desc").click(function () {
      $(".property_sortby_desc").addClass("active")
      $(".property_sortby_asc").removeClass("active")
    })
    // Sortby option price

    // Info button seo text
    $(".icon-info").click(function() {
      $(".search_text").toggleClass("active")
    })
    // Info button seo text

    // load on top
    $(document).ready(function(){
      $("html, body").scrollTop(0);
    });

    //$( ".filtersearchsoldlet" ).insertAfter( ".defaultsoldfilter" );
    // load on top
  }
  includeSoldclick = e => {
    this.setState({
      includeSoldinside: !this.state.includeSoldinside
    })
  }
  render() {
    const searchParams = parseSearchUrl("sales", this.props.location.pathname)
    const h1 = propertyH1(searchParams)
    const areaName = searchParams.areas
    const pType = searchParams.pType

    var areaSearch = '';
    var path = this?.props?.location?.pathname;
    if(typeof window !== "undefined"){
      areaSearch = localStorage.getItem("area");
      if(path.includes("london")) {
        areaSearch = 'London';
      }
      if(path.includes("manchester")) {
        areaSearch = 'Manchester';
      }
    }
    //  if (path == "/property/to-rent/in-london" || path == "/property/to-rent/in-london/" || path == "/property/to-rent/in-london/sortby-price-asc" || path == "/property/to-rent/in-london/sortby-price-asc/") {
    //    areaSearch = 'London';
    //  }
    //  if (path == "/property/to-rent/in-manchester" || path == "/property/to-rent/in-manchester/" || path == "/property/to-rent/in-manchester/sortby-price-asc" || path == "/property/to-rent/in-manchester/sortby-price-asc/") {
    //    areaSearch = 'Manchester';
    //  }
    var area = typeof window !== "undefined" && areaSearch ? `AND city:${areaSearch}` : '';
    // console.log("area ::",area, path); 

  

    const isPageLoadBg = typeof this.props.path === "undefined"

    let locType;
    if (areaName === "london") {
      locType = "Battersea, Nine Elms, Clapham"
    } else {
      locType = areaName
    }

    let areaPropertyName;

    if (h1 === "Properties for Sale in London And Manchester") {
      areaPropertyName = "Properties for sale in London, Manchester";
    } else if ( h1 === "Apartments for sale in London" ) {
      areaPropertyName = "Apartments for sale in Battersea, Nine Elms, Clapham";
    } else if ( h1 === "Houses for sale in London" ) {
      areaPropertyName = "Houses for sale in Battersea, Nine Elms, Clapham";
    } else {
      areaPropertyName = h1;
    }

    // Filter button
    const searchFilter = e => {
      $(".filter-list").toggleClass("active")
    }
    // Filter button

    // Property list & navigate
    let urlProperty = "/"
    const propertyMap = e => {
      urlProperty = "/property-map/for-sale/"
      navigate(urlProperty)
    }

    const propertyList = e => {
      urlProperty = "/property/for-sale/"
      navigate(urlProperty)
    }
    // Property list & navigate

    var defaultDepartment = ["residential", "new_homes"];
       
    let mydepartment = ''
if(defaultDepartment){
  if(Array.isArray(defaultDepartment)){
    mydepartment = '(department:"'+(defaultDepartment).join('" OR department:"')+'")'
  } else {
    mydepartment = '(department:"'+(defaultDepartment)+'")'
  }
}

    var defaultStatus = this.state.includeSoldinside ? ["For Sale", "Sold", "Sold STC"] : ["For Sale"];
       
    let mystatus = ''
if(defaultStatus){
  if(Array.isArray(defaultStatus)){
    mystatus = '(status:"'+(defaultStatus).join('" OR status:"')+'")'
  } else {
    mystatus = '(status:"'+(defaultStatus)+'")'
  }
}

    return (
      <>
        <div className="sub-wrapper">
          <Header2 />

          {!isPageLoadBg && (
            <SeoResults
              title={h1}
              searchParams={searchParams}
              location={this.props.location}
            />
          )}

          {!isPageLoadBg &&
            <Helmet>
              <script type="application/ld+json">{`{
                "@context" : "http://schema.org",
                "@type" : "Product",
                "name" : "${h1}",
                "image" : "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/OR_Logo_115effa5b8.jpg",
                "description" : "Find property${ACTION_TYPES[pType]}${areaName.replace("-", ' ').replace('manchester','Manchetser')}. Explore the wide range of properties${ACTION_TYPES[pType]}london with Orlando Reid, specialist ${areaName.replace("-", ' ').replace('manchester','Manchetser')} estate agents.",
                "brand" : {
                  "@type" : "Organization",
                  "name" : "Orlando Reid"
                },
                "offers": {
                  "@type": "AggregateOffer",
                  "lowPrice": "50000",
                  "highPrice": "10000000",
                  "priceCurrency": "GBP"
                }
              }`}</script>
            </Helmet>
          }

          <div className="content">
            {/* <div className="filtersearchsoldlet">
              <SoldLetFilter searchState={this.state.searchState}
                searchClient={searchClient}
                indexName={index_name}
                createURL={createURL}
                onSearchStateChange={this.onSearchStateChange2}
              />
            </div> */}
            <InstantSearch
              indexName={index_name}
              searchClient={searchClient}
              searchState={this.state.searchState}
              onSearchStateChange={this.onSearchStateChange}
              createURL={createURL}
              routing="true"
            >
              <div className="filter-bar">
                <div className="d-none">
                  {/* <RefinementList
                    attribute="searchType"
                    defaultRefinement={["sales"]}
                  />
                  <RefinementList
                      attribute="status"
                      defaultRefinement={["For Sale"]}
                  />
                  <RefinementList
                      attribute="department"
                      defaultRefinement={["residential"]}
                  />
                  <RefinementList
                      attribute="department"
                      defaultRefinement={["new_homes"]}
                  />
                  <RefinementList
                      attribute="city"
                      defaultRefinement={[storageArea]}
                  /> */}
                </div>
                <Configure
                            filters={`searchType:sales AND ${mystatus} AND ${mydepartment} ${area}`}
                                
                            />
                <Container className="d-lg-flex align-items-lg-center justify-content-lg-between">
                  <PropertySearchBox />
                  <div className="filter-list-wrap">
                    <button
                      onClick={searchFilter}
                      className="filter-btn d-lg-none"
                    >
                      filter results
                    </button>
                    <ul className="filter-list">
                      <li>
                        <div className="select-dropdwn2 slct-min-price">
                          <CustomminpriceSelectbox attribute="price" />
                        </div>
                      </li>
                      <li>
                        <div className="select-dropdwn2 slct-max-price">
                          <CustommaxpriceSelectbox attribute="price" />
                        </div>
                      </li>
                      <li>
                        <div className="select-dropdwn2 slct-room">
                          <CustombedroomSelectbox attribute="bedroom" />
                        </div>
                      </li>
                      <li>
                        <div className="select-dropdwn2 slct-prprty">
                          <CustombuildingSelectbox attribute="building" />
                        </div>
                      </li>
                      <li>
                      <li>
                      <div className="filtersearchsoldlet">
                        <div className="property-filter_check">
                        {/* <CustomToggleRefinement
                             attribute="status"
                             label="Include Sold / SSTC"
                             value={['Sold']}
                             defaultRefinement={includeSold}
                         /> */}
                          <div class="ais-ToggleRefinement">
            <label class="ais-ToggleRefinement-label">
                <input type="checkbox" class="ais-ToggleRefinement-checkbox" value="" id="flexCheckDefault" 
                 checked={this.state.includeSoldinside ? "checked" : ""}
                 onChange={this.includeSoldclick} />
                <span class="ais-ToggleRefinement-labelText">Include Sold / SSTC</span>
            </label>
        </div>
        </div>

                        </div>
                        <div className="d-none">
                        {/* <CustomToggleRefinement
                            attribute="status"
                            label="Sold STC"
                            value={['Sold STC']}
                            defaultRefinement={includeSold}
                        /> */}

                        </div>
                      </li>
                      </li>
                    </ul>
                  </div>
                </Container>
              </div>

              <section className="search-result">
                <Container>
                <div className="breadcrumbs"><nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li class="breadcrumb-item">
                      <a href="/">Home</a></li>
                      <li class="breadcrumb-item"><a>Sales</a></li>
                      </ol>
                      </nav>
                </div>

                  <div className="result-head d-lg-flex align-items-lg-center justify-content-lg-between">
                    <div className="result-info d-flex align-items-start">
                      <h1>
                        {h1} (<CustomStats />)
                        <i className="icon-info">
                          <Exclamation />
                        </i>
                      </h1>
                      <ul className="views d-flex align-items-center d-lg-none">
                        <li>
                          <button className="map-marker map-view-present" onClick={propertyMap}>
                            <MapPointer />
                          </button>
                        </li>
                        <li>
                          <button
                            className="btn-map map-view-active"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="List View"
                            onClick={propertyList}
                          >
                            <Equalto />
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="notify-bar d-lg-flex align-items-lg-center">
                      <ul className="alert-list d-flex">
                        <li className="property_sortby">
                          <CustomSortBy
                            items={[
                              {
                                value: index_name + "_price_asc",
                                label: "Lowest Price",
                                class: "property_sortby_asc",
                              },
                              {
                                value: index_name,
                                label: "Highest Price",
                                class: "property_sortby_desc active",
                              },
                            ]}
                            defaultRefinement={index_name}
                          />
                        </li>
                        <li>
                          <a
                            href="https://orlandoreid.propertyfile.co.uk/"
                            target="_blank"
                          >
                            <button className="btn-bell">
                              <Notification />
                              Get Alerts{" "}
                              <span className="d-none d-md-inline">
                                for New Properties
                              </span>
                            </button>
                          </a>
                        </li>
                      </ul>
                      <ul className="views d-none d-lg-flex align-items-lg-center">
                        <li>
                          <button
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Map View"
                            className="map-marker map-view-present"
                            onClick={propertyMap}
                          >
                            <MapPointer />
                          </button>
                        </li>
                        <li>
                          <button
                            className="btn-map map-view-active"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="List View"
                            onClick={propertyList}
                          >
                            <Equalto />
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="result-head search_text">
                    {areaPropertyName === 'Properties for sale in London, Manchester' ?
                    <p>Discover a wide range of {areaPropertyName} and the surrounding area in London with Orlando Reid. Refine your search using the filters below and for more information about sales property in <Link to="/contact/our-offices/london-offices/battersea-and-nine-elms/">Battersea</Link>, Nine Elms, <Link to="/contact/our-offices/london-offices/clapham/">Clapham</Link> and <Link to="/contact/our-offices/manchester-offices">Manchester</Link> please contact Orlando Reid Estate Agents.</p>
                    :
                  <p>Discover a wide range of {areaPropertyName} and the surrounding area of Manchester with Orlando Reid. Refine your search using the filters below and for more information about {pType} property in <span className="property_area_captialize">{locType}</span> please contact Orlando Reid Estate Agents.</p>
                  }
                  </div>

                  <div className="result-list-wrap">
                    <CustomInfiniteHits location={this.props.location} />
                    <CustomNoStats />
                  </div>
                </Container>
              </section>
            </InstantSearch>


          </div>

          <Footer storagearea ={areaSearch} searchtype='sales' areaName={areaName.replace("-", ' ')} />
        </div>
      </>
    )
  }
}

export default PropertySearchSales
